const Title = () => {
    return <div className="mt-3" style={{background: 'var(--primary)'}}>
        <p 
            style={{
                color: '#fff',
                fontWeight: '700',
                fontSize: '24px',
                width: '80%',
                padding: '30px 18px',
                margin: 'auto'
            }}
        >
            إيمانا منا بنشر ثقافة العمل التطوعي في مجتمعنا ومن اجل الإرتقاء بروح المبادرة والمشاركة الإيجابية لأفراد المجتمع لتحقيق الصالح العام تم الإتفاق لتأسيس فريق رؤية التطوعي
        </p>
    </div>
}
export default Title