import { Button, Col, Modal, Row } from "react-bootstrap"

const PDFModal = ({modal, setModal, item})=>{
    return(
        <Modal className={"ar fade addActivity"} style={{textAlign: 'right'}} show={modal} onHide={()=>{
            setModal()
            }}>
            <Modal.Header>
            <Modal.Title>
                ملف Pdf
            </Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: 'auto', left: '0'}}
                onClick={()=>{
                    setModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <iframe 
                                width='100%'
                                height='400px'
                                src={`https://docs.google.com/gview?url=${item?.url}&embedded=true`} 
                                title='pdf' 
                            ></iframe>
                            {/* <iframe src={item?.url} title='pdf' ></iframe> */}
                        </Col>
                    </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={setModal} variant="danger light">
                اغلق
                </Button>
            </Modal.Footer>
        </Modal>)
}

export default PDFModal;