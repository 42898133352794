import { API_BASE_URL_ENV } from "../common/common";
import BaseService from "./BaseService";
import http from './HttpService'

const apiEndpoint = API_BASE_URL_ENV() + "/team";
const apiContactUsEndpoint = API_BASE_URL_ENV() + "/contactUs/team";
const apiAdminEndpoint = API_BASE_URL_ENV() + "/team/admin";

export default class TeamService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  createItem(type, data) {
    return http.post(`${apiEndpoint}/${type}`, data);
  }

  send(data) {
    return http.post(apiContactUsEndpoint, data);
  }

  getItemList(type, id, params) {
    return http.get(`${apiEndpoint}/${type}/${id}`, {params});
  }

  getAdminOwner(params) {
    return http.get(apiAdminEndpoint, {params});
  }
}
