import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo1.png'
import './style.css'
import { NavDropdown } from 'react-bootstrap';
import { useEffect, useState } from 'react';

function NavBar() {
  const [showTeamDropdown, setShowTeamDropdown] = useState(false);
  const [showGalleryDropdown, setShowGalleryDropdown] = useState(false);
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },[])

  return (
    <Navbar collapseOnSelect expand="lg" fixed="top" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand>
          <NavLink to="/">
            <img src={logo} alt="Logo" height='60px' />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto">
            <NavLink to="/" className='nav-link'>
                الرئيسية
            </NavLink>
            {/* <NavLink to="/activities" className='nav-link'>
            الأقسام
            </NavLink> */}
            <NavLink to="/news" className='nav-link'>أخبار المنطقه</NavLink>
            <NavDropdown title="المكتبه" id="library-nav-dropdown" show={showGalleryDropdown} onToggle={()=> setShowGalleryDropdown(prev=> !prev)}>
              <NavLink to="/gallery/image" className="dropdown-item" onClick={()=> setShowGalleryDropdown(false)}>صور</NavLink>
              <NavLink to="/gallery/video" className="dropdown-item" onClick={()=> setShowGalleryDropdown(false)}>فيديوهات</NavLink>
              <NavLink to="/gallery/pdf" className="dropdown-item" onClick={()=> setShowGalleryDropdown(false)}>ملفات</NavLink>
            </NavDropdown>
            <NavDropdown title="الفرق" id="teams-nav-dropdown"  show={showTeamDropdown} onToggle={()=> setShowTeamDropdown(prev=> !prev)}>
              <NavLink to="/general-team" className="dropdown-item" onClick={()=> setShowTeamDropdown(false)}>الفريق العام</NavLink>
              <NavLink to="/teams" className="dropdown-item" onClick={()=> setShowTeamDropdown(false)}>الفرق الفرعيه</NavLink>
            </NavDropdown>
            <NavLink to="/contact-us" className='nav-link'>اتصل بنا</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;