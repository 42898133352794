
import { Carousel } from 'primereact/carousel';
import { useEffect, useState } from 'react';
import './style.css'
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AboutService from '../../services/AboutService';

const About = () =>{
    const [data, setData] = useState({})
    const navigate = useNavigate()
    const aboutService = new AboutService()
    const tabs = [
        {label: "تأسيس", value: 'establishment'},
        {label: "رؤية", value: 'vision'},
        {label: "أهداف", value: 'goals'},
    ]
    const [selected, setSelected] = useState(tabs[0])
    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    useEffect(()=>{
        let establishmentReq = aboutService.getList({type: tabs[0]?.value})
        let visionReq = aboutService.getList({type: tabs[1]?.value})
        let goalsReq = aboutService.getList({type: tabs[2]?.value})
        Promise.all([establishmentReq, visionReq, goalsReq]).then(res=>{
            setData({
                establishment: res[0]?.data?.data?.data[0],
                vision: res[1]?.data?.data?.data[0],
                goals: res[2]?.data?.data?.data[0],
            })
        }).catch(e=> console.log(e))
    },[selected])

    const productTemplate = (product) => {
        return (
            <div className='m-4'>
            <div className="new-card border-round p-3">
                <div className='p-2'>
                    <div className="mb-3">
                        <img src={product.image} alt={'about'} height='133' className="w-100 shadow-2" />
                    </div>
                    <div>
                        <p className="card-description">{product.description}</p>
                    </div>
                </div>
            </div>
            </div>
        );
    };

    return <div id="about" className="about">
    <div className='container'>
        <h1 className='title'>
         لمحة عن رؤية
        </h1>

        <Row>
            <Col md={12} className='mb-4 mt-4'>
                <div className="new-card border-round p-3">
                    <div className='p-2 d-flex justify-content-between align-items-center'>
                        <div>
                            <h2>{tabs[0].label}</h2>
                            <div style={{width: '80%'}} dangerouslySetInnerHTML={{ __html: data?.establishment?.description }}></div>
                        </div>
                        <div className="text-center">
                            <img src={data?.establishment?.image} alt={'about'} height='120' width='120'  className="shadow-2" />
                        </div>
                    </div>
                </div>
            </Col>
            <div className='col-6'>
                <div className="new-card border-round p-3">
                    <div className='p-2 d-flex'>
                        <div>
                            <img src={data?.vision?.image} alt={'about'} height='100' width='100'  className="shadow-2 mb-4" />
                            <h2>{tabs[1].label}</h2>
                            <div dangerouslySetInnerHTML={{ __html: data?.vision?.description }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-6'>
                <div className="new-card border-round p-3">
                    <div className='p-2 d-flex'>
                        <div>
                            <img src={data?.goals?.image} alt={'about'} height='100' width='100'  className="shadow-2 mb-4" />
                            <h2>{tabs[2].label}</h2>
                            <div dangerouslySetInnerHTML={{ __html: data?.goals?.description }}></div>
                        </div>
                    </div>
                </div>
            </div>   
        </Row>
    </div>
    </div>
}
export default About;