
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import TeamService from '../../services/TeamService';
import email from "../../assets/email-icon1.svg"
import user from "../../assets/user.svg"
import './style.css'
import Loader from '../../common/Loader';

const GeneralTeam = () =>{
    const [loading, setLoading] = useState(false)
    const teamService = new TeamService()
    const [data, setData] = useState([])

    useEffect(()=> {
        setLoading(true)
        teamService.getAdminOwner({role: 'owner'}).then(res=>{
          if(res?.status){
            let info = res?.data?.data?.data
            setData(info)
          }
          setLoading(false)
        }).catch(()=> setLoading(false))
    } ,[])

    return <div id="teams" className="teams">
    <div className='container'>
        <h1 className='title'>
         الفريق العام
        </h1>

        <Row className='mt-5'>
            {loading && <Loader />}

            {(data?.length && !loading) && data?.map(leader => { 
                return <div className='col-md-4 col-12 m-auto' key={leader?.id}>
                    <div className="new-card border-round p-3">
                                    <div className='text-center'>
                                        <img src={leader?.avatar || user} style={{borderRadius: '50%'}} alt={'about'} height='130' className="w-50 m-auto shadow-2" />
                                        <p className="card-title">{leader?.f_name} {leader?.l_name}</p>
                                        <p>رئيس الفريق</p>
                                        
                                        <span className='mt-3' style={{fontSize: '12px'}}>طرق التواصل</span>
                                        <div className='d-flex justify-content-evenly mt-1'>
                                            {/* {item?.facebook && <a href={item?.facebook} target='_blank' rel="noreferrer">
                                                <img src={facebook} alt='facebook' width={30} />
                                            </a>}
                                            {item?.instagram && <a href={item?.instagram} target='_blank' rel="noreferrer">
                                                <img src={instagram} alt='instagram' width={30} />
                                            </a>}
                                            {item.snapchat && <a href={item.snapchat} target='_blank' rel="noreferrer">
                                                <img src={snapchat} alt='snapchat' width={30} />
                                            </a>} */}
                                            {leader?.email && <a href={`mailto:${leader.email}`} target='_blank' rel="noreferrer">
                                                <img src={email} alt='email' width={30} />
                                            </a>}
                                        </div>
                                    </div>
                    </div>
                </div>
            })}
        </Row>
    </div>
    </div>
}
export default GeneralTeam;