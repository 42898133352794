
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import TeamService from '../../services/TeamService';
import { toast } from 'react-toastify';
import facebook from "../../assets/facebook1.svg"
import snapchat from "../../assets/snapchat.svg"
import email from "../../assets/email-icon1.svg"
import instagram from "../../assets/instagram1.svg"
import user from "../../assets/user.svg"
import './style.css'
import Loader from '../../common/Loader';

const Teams = () =>{
    const [formData, setFormData] = useState({
        phone: '',
        message: ''
    })
    const [loading, setLoading] = useState(false)
    const [teams, setTeams] = useState([])
    const [team, setTeam] = useState('')
    const [data, setData] = useState([])
    const teamService = new TeamService()
    const tabs = [
        {label: "الأهداف", value: 'goal'},
        {label: "الأعمال", value: 'work'},
        {label: "الصور", value: 'teamGallery'},
        {label: "أعضاء الفريق", value: 'member'},
        {label: "تواصل مع الفريق", value: 'contact'},
    ]
    const [selected, setSelected] = useState(tabs[0])
    const [leader, setLeader] = useState({})

    useEffect(()=> {
        teamService.getList().then(res=>{
          if(res?.status){
            let teamsInfo = res?.data?.data?.data?.map(inf => {
                return {
                    label: inf?.name,
                    value: inf?.id,
                    user: inf?.user
                }
            })
            setTeams(teamsInfo)
            if(!!teamsInfo?.length){ 
                setTeam(teamsInfo[0])
                setLeader(teamsInfo[0]?.user)
            }
          }
        })
    } ,[])

    useEffect(()=>{
        if(selected?.value === 'goal' || selected?.value === 'work' || 
           selected?.value === 'teamGallery' || selected?.value === 'member'){
            if(!!team){
                setLoading(true)
                teamService.getItemList(selected.value, team.value).then(res=>{
                    if(res?.status === 200){
                        setData(res?.data?.data?.data)
                    }
                    setLoading(false)
                }).catch(e=> setLoading(false))
            }
        }
    },[team, selected])

    const send = () => {
        if(!formData?.phone || !formData?.message){
            toast.error("املئ الخانات")
            return
        }
        let data = {
            phone: formData?.phone,
            message: formData?.message,
            team_id: team?.value
        }
        teamService.send(data).then(res=>{
            if(res?.status){
                setFormData({
                    phone: '',
                    message: ''
                })
                toast.success('تم ارسال طلب الإنضمام')
            }
        }).catch(e=> console.log(e))
    }
    return <div id="teams" className="teams">
    <div className='container'>
        <h1 className='title'>
          الفرق الفرعيه
        </h1>

        <Row>
            <Col md={3} className='p-3 pb-0'>
                <label className="text-label mb-2">الفريق</label>
                <Select
                    value={team}
                    name="type"
                    placeholder= {"اختار ..."}
                    options={teams}
                    onChange={(e)=> setTeam(e)}
                />
            </Col>
        </Row>

        {(data?.length === 0 && !loading) && <h2 className='text-center mt-5'>لا يوجد</h2>}
        <Row className='mt-5'>
            <Col md={3} sm={2}>
                <div className='tabs-div'>
                    {tabs?.map(tab => {
                        return <p 
                            className={`mb-0 ${selected?.value === tab?.value ? 'active' : ''}`} 
                            onClick={()=> setSelected(tab)}
                        >{tab?.label}</p>
                    })}
                </div>
            </Col>
            <Col md={9} sm={10}>
                {(selected?.value === 'member' && !loading) &&  <Col md={4} sm={6} className='mb-3 px-3 m-auto'>
                            <div className="new-card border-round p-3">
                                <div className='text-center'>
                                    <img src={leader?.avatar || user} style={{borderRadius: '50%'}} alt={'about'} height='130' className="w-50 m-auto shadow-2" />
                                    <p className="card-title">{leader?.f_name} {leader?.l_name}</p>
                                    <p>رئيس الفريق</p>
                                    
                                    <span className='mt-3' style={{fontSize: '12px'}}>طرق التواصل</span>
                                    <div className='d-flex justify-content-evenly mt-1'>
                                        {/* {item?.facebook && <a href={item?.facebook} target='_blank' rel="noreferrer">
                                            <img src={facebook} alt='facebook' width={30} />
                                        </a>}
                                        {item?.instagram && <a href={item?.instagram} target='_blank' rel="noreferrer">
                                            <img src={instagram} alt='instagram' width={30} />
                                        </a>}
                                        {item.snapchat && <a href={item.snapchat} target='_blank' rel="noreferrer">
                                            <img src={snapchat} alt='snapchat' width={30} />
                                        </a>} */}
                                        {leader?.email && <a href={`mailto:${leader.email}`} target='_blank' rel="noreferrer">
                                            <img src={email} alt='email' width={30} />
                                        </a>}
                                    </div>
                                </div>
                            </div>
                </Col>}
                <Row className='px-3'>
                    {((selected?.value === 'goal' || selected?.value === 'work' || selected?.value === 'teamGallery')  && !loading) && data?.map((item, index)=>{
                        return <Col md={4} sm={6} key={index} className='mb-3'>
                            <div>
                            <div className="new-card border-round p-3">
                                <div>
                                    {selected?.value === 'teamGallery' && <img src={item?.url} alt={'about'} height='250' className="w-100 shadow-2" />}
                                    
                                    {(selected?.value === 'work' || selected?.value === 'goal') && <div>
                                        <div className="mb-3">
                                            <img src={item.image} alt={item?.title} height='200' className="w-100 shadow-2" />
                                        </div>
                                        <div>
                                            <p className="card-title">{item.title}</p>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            </div>
                        </Col>
                    })}

                    {(selected?.value === 'contact' && !loading) && <Col md={8} sm={12}>
                    <Row className='contact'>
                        <Col md={12} className='p-3 mb-4'>
                            <label>رقم الهاتف</label>
                            <input 
                                type='number'
                                name='phone'
                                value={formData.phone}
                                required
                                onChange={e=> setFormData({...formData, phone: e.target?.value})}
                            />
                        </Col>
                        <Col md={12} className='p-3 mb-4'>
                            <label>الخبرات</label>
                            <textarea
                                name='message'
                                placeholder='الخبرات'
                                value={formData.message}
                                required
                                onChange={e=> setFormData({...formData, message: e.target?.value})}
                            />
                        </Col>
                        <Col md={12} className='text-center'>
                            <Button 
                                variant='primary'
                                className=''
                                onClick={send}
                            >
                                إرسال
                            </Button>
                        </Col>
                    </Row>
                    </Col>}

                    {(selected?.value === 'member' && !loading) &&  data?.map((item, index)=>{
                        return <Col md={4} sm={6} key={index} className='mb-3'>
                            <div className="new-card border-round p-3">
                                <div className='text-center'>
                                    <img src={item?.image} style={{borderRadius: '50%'}} alt={'about'} height='130' className="w-50 m-auto shadow-2" />
                                    <p className="card-title">{item.name}</p>
                                    <p>عضو</p>
                                    
                                    <span className='mt-3' style={{fontSize: '12px'}}>طرق التواصل</span>
                                    <div className='d-flex justify-content-evenly mt-1'>
                                        {item?.facebook && <a href={item?.facebook} target='_blank' rel="noreferrer">
                                            <img src={facebook} alt='facebook' width={30} />
                                        </a>}
                                        {item?.instagram && <a href={item?.instagram} target='_blank' rel="noreferrer">
                                            <img src={instagram} alt='instagram' width={30} />
                                        </a>}
                                        {item.snapchat && <a href={item.snapchat} target='_blank' rel="noreferrer">
                                            <img src={snapchat} alt='snapchat' width={30} />
                                        </a>}
                                        {item.email && <a href={`mailto:${item.email}`} target='_blank' rel="noreferrer">
                                            <img src={email} alt='email' width={30} />
                                        </a>}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    })}

                    {loading && <Loader />}
                </Row>
            </Col>
        </Row>
    </div>
    </div>
}
export default Teams;